var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.title)+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.contents,"search":_vm.search},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s())]}},{key:"item.placed_at",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.placed_at.toDate()))]}},{key:"item.selectedTime",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.selectedTime+' - '+ (item.selectedTime+1)))]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.status=='pending')?_c('v-btn',{attrs:{"color":"success","text":"","flat":""},on:{"click":function($event){return _vm.markAsCompleted(item)}}},[_vm._v("Mark As Completed")]):_c('span',[_vm._v("No Actions")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status=='cancelled')?_c('span',{staticClass:"text-danger"},[_vm._v("CancelLed")]):_vm._e(),(item.status=='pending')?_c('span',{staticClass:"text-warning"},[_vm._v("In Progress")]):_vm._e(),(item.status=='delivered')?_c('span',{staticClass:"blue--text"},[_vm._v("Delivered")]):_vm._e(),(item.status=='completed')?_c('span',{staticClass:"text-success"},[_vm._v("Completed")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }